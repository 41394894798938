<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <a
          @click="$router.back()"
          href="#"
          class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg d-flex flex-row align-items-center"
        >
          <i class="mdi mdi-chevron-left"></i>
          Kembali
        </a>
        <validation-observer ref="formUjian">
          <b-form @submit.prevent="submitForm" @reset="onReset">
            <div class="card card-custom card-stretch">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    Informasi Ujian
                  </span>
                </h3>
                <div class="card-toolbar">
                  <button
                    type="submit"
                    class="btn btn-success font-weight-bolder font-size-sm mr-2"
                  >
                    Simpan
                  </button>
                  <span
                    @click="$router.back()"
                    class="btn btn-secondary font-weight-bolder font-size-sm"
                  >
                    Batal</span
                  >
                </div>
              </div>
              <div class="card-body pt-4">
                <!-- <b-form-group
                  id="input-group-1"
                  label="Kode Ujian:"
                  label-for="input-1"
                  description="Contoh: KD-001"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Ujian"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="input-1"
                      v-model="currentUjian.exam_code"
                      type="text"
                      placeholder="Masukkan Kode Ujian"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group> -->
                <b-form-group
                  id="input-group-1"
                  label="Nama ujian:"
                  label-for="input-1"
                  description="Contoh: Ujian Tengah Semester"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nama Ujian"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="input-1"
                      v-model="currentUjian.name"
                      type="text"
                      placeholder="Masukkan nama ujian"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Deskripsi ujian:"
                  label-for="input-1"
                  description="Deskripsi ujian, aturan ujian, dll"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi Ujian"
                    rules="required"
                  >
                    <editor
                      v-if="!idUjian || currentUjian.description"
                      v-model="currentUjian.description"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Kategori Ujian:">
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        name="Kategori Ujian"
                        rules="required"
                      >
                        <div
                          class="spinner spinner-primary spinner-left"
                          v-if="isLoading"
                        >
                          <input class="form-control mb-5" />
                        </div>
                        <b-form-select
                          id="per-page-select"
                          v-model="currentUjian.exam_categories"
                          :options="
                            getCustomFirstExamOptions('Pilih Kategori Ujian')
                          "
                        ></b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <b-spinner v-if="isLoadingPaketSoal" small></b-spinner>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Pilih Paket Soal:"
                  label-for="input-1"
                >
                  <b-row>
                    <b-col>
                      <validation-provider
                        #default="{ errors }"
                        name="Paket Soal"
                        rules="required"
                      >
                        <b-form-select
                          v-model="selectedQuestionPack"
                          :options="options"
                        >
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                    <b-col>
                      <b-spinner v-if="isLoadingPaketSoal" small></b-spinner>
                    </b-col>
                  </b-row>
                </b-form-group>
              </div>
            </div>
            <div class="card card-custom card-stretch mt-4">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    Waktu dan Tanggal Ujian
                  </span>
                </h3>
                <div class="card-toolbar"></div>
              </div>
              <div class="card-body pt-4">
                <div
                  class="alert alert-custom alert-light-info fade show mb-10"
                  role="alert"
                >
                  <div class="alert-icon">
                    <span class="svg-icon svg-icon-3x svg-icon-info">
                      <!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24" />
                          <circle
                            fill="#000000"
                            opacity="0.3"
                            cx="12"
                            cy="12"
                            r="10"
                          />
                          <rect
                            fill="#000000"
                            x="11"
                            y="10"
                            width="2"
                            height="7"
                            rx="1"
                          />
                          <rect
                            fill="#000000"
                            x="11"
                            y="7"
                            width="2"
                            height="2"
                            rx="1"
                          />
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                  <div class="alert-text font-weight-bold">
                    Jam ditutup ujian adalah jam ditutup ujian dikurangi durasi
                    waktu ujian.
                    <div
                      v-if="
                        currentUjian.waktu &&
                        currentUjian.tanggal_ditutup &&
                        currentUjian.tanggal_ditutup_jam
                      "
                    >
                      Pegawai tidak akan bisa mengakses ujian dari tanggal
                      {{ waktuDitutup | moment("LL HH:mm") }}
                    </div>
                  </div>
                </div>
                <b-form-group
                  id="input-group-1"
                  label="Durasi waktu ujian:"
                  label-for="input-1"
                  description="Satuan menit"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Durasi Waktu Ujian"
                    rules="required"
                  >
                    <b-form-input
                      :state="errors.length > 0 ? false : null"
                      id="input-1"
                      v-model.number="currentUjian.time"
                      type="number"
                      placeholder="Masukkan durasi waktu ujian"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="input-group-1"
                      label="Tanggal dibuka ujian:"
                      label-for="input-1"
                      description="Tanggal dan jam dibuka ujian"
                    >
                      <b-input-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tanggal Dibuka"
                          rules="required"
                        >
                          <b-form-input
                            :state="errors.length > 0 ? false : null"
                            id="input-1"
                            v-model="currentUjian.tanggal_dibuka"
                            type="date"
                            :min="minStartDate"
                            placeholder="Masukkan tanggal dibuka ujian"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-input-group-append>
                          <validation-provider
                            #default="{ errors }"
                            name="Jam Dibuka"
                            rules="required"
                          >
                            <b-form-input
                              :state="errors.length > 0 ? false : null"
                              id="input-1"
                              v-model="currentUjian.tanggal_dibuka_jam"
                              type="time"
                              placeholder="Masukkan jam dibuka ujian"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="input-group-1"
                      label="Tanggal ditutup ujian:"
                      label-for="input-1"
                      description="Tanggal dan jam ditutup ujian"
                    >
                      <b-input-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tanggal Ditutup"
                          rules="required"
                        >
                          <b-form-input
                            :state="errors.length > 0 ? false : null"
                            id="input-1"
                            v-model="currentUjian.tanggal_ditutup"
                            type="date"
                            :min="minEndDate"
                            placeholder="Masukkan tanggal ditutup ujian"
                          ></b-form-input>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-input-group-append>
                          <validation-provider
                            #default="{ errors }"
                            name="Jam Ditutup"
                            rules="required"
                          >
                            <b-form-input
                              :state="errors.length > 0 ? false : null"
                              id="input-1"
                              v-model="currentUjian.tanggal_ditutup_jam"
                              type="time"
                              placeholder="Masukkan jam ditutup ujian"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
            <div class="mt-3">
              <PilihHakAkses
                ref="piliihHakAkses"
                :initValue="currentUjian.level"
                @selected="getHakAkses"
              />
            </div>
          </b-form>
        </validation-observer>
        <!-- <b-modal modal-lg v-model="modalAddClass" hide-footer hide-header title="Daftar Kelas">
          <div class="d-block text-center">
            <b-row class="justify-content-end">
              <button @click="closeModal" class="btn btn-secondary font-weight-bolder font-size-sm mr-2">
                batal
              </button>

              <button class="btn btn-primary font-weight-bolder font-size-sm mr-2" @click="addClass">
                Simpan
              </button>
            </b-row>
            <br />
            <b-row class="justify-content-between">
              <b-col cols="auto">
                <b-form-group>
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="auto">
                <b-form-group label="Filter" label-for="filter-input" label-cols-sm="3" label-align-sm="right"
                  label-size="sm" class="mb-0">
                  <b-input-group size="sm">
                    <b-form-input id="filter-input" v-model="filter" type="search"
                      placeholder="Type to Search"></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-table :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered"
              :items="currentClassList.data" :fields="fields" :busy="isLoading" show-empty class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
                card-table
              " responsive>
              <template #empty="scope">
                <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
              </template>
              <template #emptyfiltered="scope">
                <h6 class="text-center">Tidak ada Kelas ditemukan</h6>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template #cell(index)="row">
                {{ perPage * (currentPage - 1) + (row.index + 1) }}
              </template>

              <template #cell(action)="data">
                <span @click="chooseKelas(data.item)" :class="`btn btn-${isExist(data.item._id) ? 'warning' : 'info'
                  } font-weight-bolder font-size-sm`">
                  <i class="flaticon2-user"></i>
                  {{ isExist(data.item._id) ? "Kelas Dipilih" : "Pilih Kelas" }}
                </span>
                &nbsp;
              </template>
            </b-table>
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
              class="my-0"></b-pagination>
          </div>
        </b-modal> -->
      </div>
    </div>
    <!-- <b-modal size="xl" :no-close-on-backdrop="true" v-model="modalAkses" centered hide-footer scrollable
      title="Menu Akses">
    </b-modal> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import {
  CLEAR_UJIAN,
  CREATE_UJIAN,
  GET_ONE_UJIAN,
  UPDATE_UJIAN,
  UNASSIGN_CLASS_FROM_EXAM,
  ASSIGN_CLASS_TO_EXAM,
} from "@/core/services/store/ujian.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_CLASS_LIST,
  CLEAR_CLASS_LIST,
} from "@/core/services/store/kelas.module";
import { GET_PAKET } from "@/core/services/store/paket.module";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import Swal from "sweetalert2";
// import {required} from 'vee-validate';
import moment from "moment-timezone";
import PilihHakAkses from "../hak-akses/PilihHakAkses.vue";

import { globalMixin } from "../../../core/mixin/globalMixin";
moment.tz.setDefault("Asia/Jakarta");

export default {
  name: "UjianForm",
  mixins: [globalMixin],
  components: {
    ValidationObserver,
    ValidationProvider,
    PilihHakAkses,
  },
  computed: {
    ...mapState({
      errors: (state) => state.ujian.errors,
    }),
    ...mapGetters(["currentUjian", "currentClassList"]),
    idUjian() {
      return this.$route.params.idUjian;
    },
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    waktuDitutup() {
      var date = Date.parse(
        `${this.currentUjian.tanggal_ditutup} ${this.currentUjian.tanggal_ditutup_jam}`
      );
      date = new Date(date);
      date.setMinutes(date.getMinutes() - this.currentUjian.waktu);
      return date;
    },
  },
  data() {
    return {
      editorOption: {
        // Some Quill options...
      },
      isLoading: false,
      isLoadingPaketSoal: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
      pageOptions: [10, 20, 50, { value: 100, text: "More" }],
      currentUjian: {
        exam_type: null,
        exam_categories: null,
        level: [],
      },
      filter: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "class_name",
          label: "Nama Kelas",
          sortable: true,
        },
        {
          key: "subject",
          label: "Subject",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      selectedQuestionPack: null,
      kelas: [],
      options: [],
      currentAssignClass: [],
      modalAddClass: false,
      modalAkses: false,
      minStartDate: null,
      minEndDate: null,
    };
  },
  watch: {
    "currentUjian.exam_categories": function (newVal) {
      this.selectedQuestionPack = null;
      this.currentUjian.exam_type = this.examOptions.filter(
        (x) => x.value == newVal
      )[0].exam_type;
      this.getPaket();
    },
    "currentUjian.tanggal_dibuka": function (newVal) {
      this.minEndDate = newVal;
    },
  },
  beforeMount() {
    this.getPaket();

    if (!this.idUjian) {
      this.minStartDate = moment().format("YYYY-MM-DD");
      this.minEndDate = moment().format("YYYY-MM-DD");
    }
  },
  mounted() {
    this.$store.dispatch(CLEAR_UJIAN);
    this.$store.dispatch(CLEAR_CLASS_LIST);

    // await Promise.all([this.getKelas(), this.getPaket()]);
    if (this.idUjian) {
      this.getData();
    }

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.idUjian ? "Edit" : "Buat"} Ujian` },
    ]);
  },
  methods: {
    isExist: function (item) {
      if (this.kelas.indexOf(item) !== -1) {
        return true;
      }

      return false;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    chooseKelas(item) {
      if (this.kelas.includes(item._id)) {
        this.kelas.splice(this.kelas.indexOf(item._id), 1);
      } else {
        this.kelas.push(item._id);
      }
    },
    getKelas() {
      this.isLoading = true;
      const params = new URLSearchParams();
      params.append("limit", this.perPage);
      params.append("page", this.currentPage);

      this.$store
        .dispatch(GET_CLASS_LIST, params.toString())
        .then(() => {
          this.isLoading = false;
          this.totalRows = this.currentClassList.total;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Kelas`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_UJIAN, this.idUjian)
        .then((response) => {
          this.currentUjian = response.data;

          const tanggal_dibuka = moment(this.currentUjian.opening_time).format(
            "YYYY-MM-DD"
          );
          const tanggal_ditutup = moment(this.currentUjian.closing_time).format(
            "YYYY-MM-DD"
          );

          const start_time = moment(this.currentUjian.opening_time).format(
            "HH:mm"
          );

          const end_time = moment(this.currentUjian.closing_time).format(
            "HH:mm"
          );

          this.currentUjian.tanggal_dibuka = tanggal_dibuka;
          this.currentUjian.tanggal_ditutup = tanggal_ditutup;
          this.currentUjian.tanggal_dibuka_jam = start_time;
          this.currentUjian.tanggal_ditutup_jam = end_time;

          const data = this.currentClassList.data.filter((item) =>
            this.currentUjian.class_id.includes(item._id)
          );

          this.currentAssignClass = this.currentUjian.class_id;
          this.totalRows = data.length;

          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    unassignClass() {
      const _data = {
        exam_id: this.idUjian,
        class_id: this.kelas,
      };

      this.isLoading = true;
      this.$store
        .dispatch(UNASSIGN_CLASS_FROM_EXAM, _data)
        .then((response) => {
          Swal.fire({
            title: response.message,
            text: `Berhasil Mengubah Daftar Kelas`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getData();
          this.kelas = [];
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });
    },
    submitForm() {
      if (this.currentUjian.level.length == 0) {
        Swal.fire({
          title: "Pemberitahuan",
          text: "Silahkan isi level akses",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        }).then(() => {
          // const el = this.$refs.piliihHakAkses;
          // if (el) {
          //   // Use el.scrollIntoView() to instantly scroll to the element
          //   el.scrollIntoView({ behavior: 'smooth' });
          // }
        });
        return;
      }

      this.$refs.formUjian.validate().then((success) => {
        if (success) {
          this.currentUjian.question_pack_id = this.selectedQuestionPack;

          if (!this.idUjian) {
            this.currentUjian.is_published = false;
            this.currentUjian.class_id = this.kelas;
          } else {
            this.$delete(this.currentUjian, "class_id");
            this.$delete(this.currentUjian, "createdAt");
            this.$delete(this.currentUjian, "created_id");
            this.$delete(this.currentUjian, "deleted_at");
            this.$delete(this.currentUjian, "is_deleted");
            this.$delete(this.currentUjian, "class_id");
          }

          const _data = JSON.parse(JSON.stringify(this.currentUjian));
          _data.opening_time = moment(
            `${this.currentUjian.tanggal_dibuka} ${this.currentUjian.tanggal_dibuka_jam}`
          )
            .utc()
            .format();
          _data.closing_time = moment(
            `${this.currentUjian.tanggal_ditutup} ${this.currentUjian.tanggal_ditutup_jam}`
          )
            .utc()
            .format();

          this.isLoading = true;
          this.$store
            .dispatch(this.idUjian ? UPDATE_UJIAN : CREATE_UJIAN, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.idUjian ? "ubah" : "buat"} ujian`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
              this.$router.push(`/ujian`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Membuat Ujian`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    getPaket() {
      if (!this.currentUjian.exam_categories) {
        this.options = [];
        var newValue = [];
        newValue.push({
          text: "Piih Paket",
          value: null,
        });
        this.options = newValue;
        return;
      }

      this.isLoadingPaketSoal = true;

      const params = new URLSearchParams();
      params.append("limit", 100);
      params.append("page", 1);
      params.append(
        "where['exam_categories']",
        this.currentUjian.exam_categories
      );

      this.$store
        .dispatch(GET_PAKET, params.toString())
        .then((response) => {
          this.options = [];
          var newValue = [];
          newValue.push({
            text: "Piih Paket",
            value: null,
          });
          response.data.data.map(function (value, key) {
            const item = {
              text: value.name,
              value: value._id,
            };

            newValue.push(item);
          });

          this.options = newValue;

          if (this.idUjian) {
            this.selectedQuestionPack = this.currentUjian.question_pack_id._id;
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });

      this.isLoadingPaketSoal = false;
    },
    addClass() {
      this.isLoading = true;
      const _data = {
        exam_id: this.idUjian,
        class_id: this.kelas,
      };
      this.$store
        .dispatch(ASSIGN_CLASS_TO_EXAM, _data)
        .then((response) => {
          Swal.fire({
            title: response.message,
            text: `Berhasil menambahkan kelas ujian`,
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
          this.getData();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Membuat Ujian`,
            variant: "danger",
            solid: true,
          });
        });

      this.modalAddClass = false;
      this.kelas = [];
    },
    onReset() {
      this.kelas = [];
    },
    openModal() {
      this.modalAddClass = true;
    },
    closeModal() {
      this.modalAddClass = false;
      this.kelas = [];
    },
    onEditorBlur(quill) {},
    onEditorFocus(quill) {},
    onEditorReady(quill) {},
    onEditorChange({ quill, html, text }) {
      this.currentUjian.deskripsi = html;
    },
    getHakAkses(item) {
      this.currentUjian.level = item;
    },
  },
};
</script>

<style scoped></style>
